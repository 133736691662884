// initial state
const initialState = {
  displayNav: false,
}

// types
const TOGGLE_NAV = 'TOGGLE_NAV'

// actions
export const toggleNav = () => ({ type: TOGGLE_NAV })

// reducer
export default (state = initialState, action) => {
  const {
    type,
    // payload,
  } = action
  switch (type) {
  case TOGGLE_NAV:
    const displayNav = !state.displayNav
    return { ...state, displayNav }
  default:
    return state
  }
}
