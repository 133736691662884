const theme = {
  colors: {
    background: '#FFFFFF',
    dark: '#150A0A',
    medium: '#616161',
    light: '#F8F8F8',
    accent: '#42FF00',
    highlight: '#CAD9C5',
    text: '#000000',
    shadow: '#000000',
  },
  fonts: {
    accent: 'Horizon',
    body: 'Chronicle Display',
    heading: 'Object Sans',
  },
  fontSizes: {
    xSmall: '9px',
    smallish: '10px',
    small: '12px',
    medium: '14px',
    large: '18px',
    xLarge: '20px',
    xxLarge: '22px',
    h4: '24px',
    h3: '28px',
    h2: '32px',
    h1: '36px',
    super: '48px',
    superbig: '60px',
    title: '72px',
    largest: '100px',
  },
}

export default theme
