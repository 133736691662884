import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import * as Actions from '../state/app'
import {
  globalStyles as GlobalStyles,
  theme
} from '../styles'
import Header from '../components/Site/Header'
import Footer from '../components/Site/Footer'

const Root = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  min-height: 100vh;
  width: 100vw;
  background: ${props => props.color};
`

const Content = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

class MainLayout extends Component {
  render() {
    const { children, location: { pathname } } = this.props
    return (
      <Root shade={theme.colors.background}>
        <Helmet>
          <title>eee official</title>
        </Helmet>
        <GlobalStyles />
        <Content>
          <Header />
          {children}
          {/* <Footer /> */}
        </Content>
      </Root>
    )
  }
}

export default connect(
  state => ({
    displayNav: state.app.displayNav,
  }),
  dispatch => ({
    toggleNav: () => dispatch(Actions.toggleNav()),
  })
)(MainLayout)

