import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { TimelineLite, TweenMax } from 'gsap/all'
import CSSPlugin from 'gsap/CSSPlugin'

import * as Actions from '../../state/app'
import { theme, media } from '../../styles'

const C = CSSPlugin // eslint-disable-line no-unused-vars

const Container = styled.div`
  z-index: 999;
  position: fixed;
  left: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  ${media.phone`
  left: 25px;
  top: 25px;
  `}
`

const NavTop = styled.div`
  display: flex;
  flex-direction: row;
`

const BoxOuter = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  background-color: ${props => props.color};
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.thone`
    height: 80px;
    width: 80px;
  `}
  ${media.phone`
    height: 60px;
    width: 60px;
  `}
`

const Icon = styled.h1.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  font-family: ${theme.fonts.accent};
  font-size: ${theme.fontSizes.title};
  margin: 0;
  font-weight: normal;
  transform: translate(0px, -7px);
  color: ${props => props.color};
  ${media.thone`
    font-size: ${theme.fontSizes.super};
  `}

  &:active,
  &:hover {
    color: ${props => props.color};
  }
`

const Menu = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  display: block;
  position: relative;
  z-index: 1;
  
  span {
    display: block;
    width: 20px;
    height: 1px;
    margin-bottom: 5px;
    position: relative;
    background: ${props => props.color};
    z-index: 1;
    transform-origin: 4px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                opacity 0.55s ease;
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  input:checked ~ span {
    opacity: 1;
    transform: rotate(-45deg) translate(-2px,1px);
    background: ${props => props.color};
  }

  input:checked ~ span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ span:nth-last-child(3) {
    transform: rotate(45deg) translate(4px,3px);
  }
`

const Input = styled.input`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;  
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
`

const NavContent = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  background-color: ${props => props.color};
  width: 30vw;
  display: flex;
  padding: 15%;
  display: none;
  ${media.thone`
    width: 70vw;
  `}
`

const NavLinksOuter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const NavLink = styled.p.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  cursor: pointer;
  font-size: ${theme.fontSizes.xLarge};
  font-family: ${theme.fonts.body};
  ${media.thone`
    font-size: ${theme.fontSizes.medium};
  `}
  &:hover {
    color: ${theme.colors.medium};
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.container = null
    this.navOuter1 = null
    this.navOuter2 = null
    this.navContent = null
    this.navHamburger = []
    this.navLinks = []
    this.navHover = new TimelineLite({ paused: true })
    this.navTimeline = new TimelineLite({ paused: true })
    this.mouseEnter = this.mouseEnter.bind(this)
    this.mouseLeave = this.mouseLeave.bind(this)
    this.keyDown = this.keyDown.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.navTimeline
      .set(this.navContent, { display: 'none' })
      .set(this.navLinks, { opacity: 0 })
      .from(this.navContent, 0.5, {
        scaleY: 0,
        display: 'flex',
        transformOrigin: 'top center',
        ease: 'Power4.easeOut'
      })
      .to(this.navContent, 0, {
        display: 'flex'
      })
      .staggerTo(this.navLinks, 0.5, {
        opacity: 1,
        ease: 'Power1.easeIn',
        delay: 0.1
      }, 0.3)
    
    document.addEventListener('keydown', this.keyDown, false)
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentDidUpdate(prevProps) {
    const { displayNav } = this.props
    if (prevProps.displayNav !== displayNav) {
      if (displayNav) {
        this.navTimeline.play().duration(1.3)
      } else {
        this.navTimeline.reverse().duration(0.5)
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false)
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  keyDown(event) {
    if (event.keyCode === 27) {
      this.props.toggleNav()
    }
  }

  mouseEnter(type) {
    const { colors: { light } } = theme
    if (type === 'menu') {
      // TweenMax.to(this.navOuter1, 0.3, { backgroundColor: 'transparent' })
      // TweenMax.to(this.navHamburger, 0.3, { background: light })
    } else if (type === 'icon') {
      // TweenMax.to(this.navOuter2, 0.3, { backgroundColor: 'transparent' })
      // TweenMax.to(this.navHamburger, 0.3, { background: light })
    }
  }

  mouseLeave(type) {
    const { colors: { light, dark } } = theme
    if (type === 'menu') {
      // TweenMax.to(this.navOuter1, 0.3, { backgroundColor: light })
      // TweenMax.to(this.navHamburger, 0.3, { background: dark })
    } else if (type === 'icon') {
      // TweenMax.to(this.navOuter2, 0.3, { backgroundColor: light })
    }
  }

  handleClick(e) {
    if (this.container.contains(e.target)) {
      return
    }
    if (this.props.displayNav) {
      this.props.toggleNav()
    }
  }

  render() {
    const { colors: { light, dark, background } } = theme
    const navLinks = [
      {
        text: 'home',
        path: '/',
        animate: false,
      },
      {
        text: 'about',
        path: '/about',
        animate: true,
      },
      {
        text: 'gallery',
        path: '/gallery',
        animate: true,
      },
      {
        text: 'order',
        path: '/request',
        animate: true,
      },
    ]
    return (
      <Container ref={ref => { this.container = ref }}>
        <NavTop>
          <Link
            swipe="true" direction="up"
            to={`/`}
          >
            <BoxOuter
              ref={ref => { this.navOuter2 = ref }}
              shade={background}
              onMouseEnter={this.mouseEnter('icon')}
              onMouseLeave={this.mouseLeave('icon')}
              onChange={this.props.toggleNav}
            >
              <Icon shade={dark}>eee</Icon>
            </BoxOuter>
          </Link>
          <BoxOuter
            ref={ref => { this.navOuter1 = ref }}
            shade={background}
            onMouseEnter={this.mouseEnter('menu')}
            onMouseLeave={this.mouseLeave('menu')}
          >
            <Menu shade={dark}>
              <Input
                type='checkbox'
                checked={this.props.displayNav}
                onChange={this.props.toggleNav}
              />
              <span ref={ref => { this.navHamburger[0] = ref }}></span>
              <span ref={ref => { this.navHamburger[1] = ref }}></span>
              <span ref={ref => { this.navHamburger[2] = ref }}></span>
            </Menu>
          </BoxOuter>
        </NavTop>
        <NavContent ref={ref => { this.navContent = ref }} shade={background}>
          <NavLinksOuter>
            {navLinks && navLinks.map((link, index) => {
              if (link.animate) {
                return (
                  <AniLink
                    swipe="true" direction="up"
                    to={link.path}
                    key={`nav_link_${index}`}
                    onClick={this.props.toggleNav}
                  >
                    <NavLink
                      shade={dark}
                      ref={ref => { this.navLinks.push(ref) }}
                    >
                      {link.text}
                    </NavLink>
                  </AniLink>
                )
              }
              return (
                <Link
                  to={link.path}
                  key={`nav_link_${index}`}
                  onClick={this.props.toggleNav}
                >
                  <NavLink
                    shade={dark}
                    ref={ref => { this.navLinks.push(ref) }}
                  >
                    {link.text}
                  </NavLink>
                </Link>
              )
            })}
          </NavLinksOuter>
        </NavContent>
      </Container>
    )
  }
}

export default connect(
  state => ({
    displayNav: state.app.displayNav,
  }),
  dispatch => ({
    toggleNav: () => dispatch(Actions.toggleNav()),
  })
)(Header)
