import ChronicleDisplayRegular from
  '../../static/fonts/Chronicle Display/ChronicleDisp-XLight.otf'
import HorizonRegular from
  '../../static/fonts/Horizon/Horizon-Regular.otf'
import ObjectSansRegular from
  '../../static/fonts/Object Sans/ObjectSans-Regular.otf'
import ObjectSansBold from
  '../../static/fonts/Object Sans/ObjectSans-Heavy.otf'

const fonts = {
  ChronicleDisplay: {
    Regular: ChronicleDisplayRegular,
  },
  Horizon: {
    Regular: HorizonRegular,
  },
  ObjectSans: {
    Regular: ObjectSansRegular,
    Bold: ObjectSansBold
  }
}

export default fonts
