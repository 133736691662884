import { createGlobalStyle } from 'styled-components'
import theme from './theme'
import fonts from './fonts'

const { colors, fonts: fontSettings, fontSizes } = theme
const { ChronicleDisplay, Horizon, ObjectSans } = fonts

const globalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Horizon';
    font-weight: 100;
    src: url('${Horizon.Regular}') format("opentype");
  }

  @font-face {
    font-family: 'Chronicle Display';
    font-weight: 100;
    src: url('${ChronicleDisplay.Regular}') format("opentype");
  }

  @font-face {
    font-family: 'Object Sans';
    font-weight: 400;
    src: url('${ObjectSans.Regular}') format("opentype");
  }

  @font-face {
    font-family: 'Object Sans';
    font-weight: 600;
    src: url('${ObjectSans.Bold}') format("opentype");
  }

  html {
    font-family: ${fontSettings.body};
    font-size: ${fontSizes.xSmall};
    box-sizing: border-box;
    background-color: ${colors.background};
  }

  /* 
    apply a natural box layout model to all elements, 
    but allowing components to change 
  */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  button:focus {
    outline: 0;
  }

  a {
    text-decoration: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */
  }
`

export default globalStyles
