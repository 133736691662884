import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { theme, media } from '../../styles'
import logo from '../../../static/images/eee.png'

const { colors, fonts, fontSizes } = theme

const Container = styled.div.attrs(({ shade }) => ({
  color: shade || '#000',
}))`
  width: 100%;
  background-color: ${props => props.color};
  padding: 5% 8%;
`

const Grid = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  display: grid;
  width: 100%;
  color: ${props => props.color};
  font-family: ${fonts.heading};
  font-weight: 100;
  font-size: ${fontSizes.small};
  grid-template-columns: 35% 30% 35%;
  grid-template-rows: 100%;
  grid-template-areas:
    "logo . links";
  ${media.tablet`
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-template-areas: 
      "links logo"
      "form form";
  `}
`

const Links = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  grid-area: links;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  a {
    color: ${props => props.color};
    text-decoration: none;
  }
  p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  ${media.tablet`
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  `}
`

const Logo = styled.div`
  grid-area: logo;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  h1 {
    font-family: ${fonts.accent};
    font-size: ${fontSizes.largest};
    margin: 0;
    font-weight: 100;
  }
  img {
    height: 12px;
    margin-right: 10px;
  }

  ${media.tablet`
    align-self: flex-end;
    justify-self: flex-end;
    display: block;
    img {
      margin-right: 0px;
    }
  `}
`

const Footer = () => {
  return (
    <Container shade={colors.dark}>
      <Grid shade={colors.text}>
        <Links shade={colors.background}>
          <div>
            <Link to="/">
              <p>home</p>
            </Link>
            <Link to="/about">
              <p>about</p>
            </Link>
            <Link to="/gallery">
              <p>gallery</p>
            </Link>
            <a href="https://www.instagram.com/eee.official/" target="_blank">
              <p>instagram</p>
            </a>
          </div>
        </Links>
        <Logo>
          <img src={logo} />
          <p>© 2019 eee official</p>
        </Logo>
      </Grid>
    </Container>
  )
}

export default Footer
